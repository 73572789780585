import React from "react";
import Layout from "../common/Layout";
import BannerSection from "./BannerSection";
// import ProjectDetails from "./ProjectDetails"
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import {
  GallerySection,
  Gmap,
  SectionCon,
  Specifications,
  Synopsis
} from "./Project";

function CompletedTemplate({ data }) {
  const project = data.completedJson;
  const withoutSpecsArr = ["the-plutus-residence", "cloud-graze", "le-grand-trianon"]

  return (
    <Layout>
      <Helmet>
        <title>{project.title}</title>
      </Helmet>
      <BannerSection
        title={project.title}
        banner={project.bg}
        brouchureUrl={project.brouchureUrl}
        video={project.video}
        tagline={project.tagline}
        certificate_number={project.certificate_number}
      />
      <SectionCon>
        <Synopsis synopsis={project.synopsis} />
        <Gmap
          gMapUrl={project.gMapUrl}
          projectLogo={project.logo}
          address={project.address}
        />
        
        {!withoutSpecsArr.includes(project.slug) ? <Specifications specification={project.specification} /> : <></>}
        <GallerySection gallery={project.gallery} />
      </SectionCon>
    </Layout>
  );
}

export const query = graphql`
  query CompletedProject($slug: String!) {
    completedJson(slug: { eq: $slug }) {
      brouchureUrl
      footer_desc
      gMapUrl
      address
      highlights
      short_desc
      slug
      synopsis
      title
      tagline
      certificate_number
      video {
        type
        link
      }
      logo {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      banner {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      specification {
        a
        q
      }
      gallery {
        title
        images {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      bg {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default CompletedTemplate;
